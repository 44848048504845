const parse = require('html-react-parser');

const ListItem = ({item_id, visual_id, image, title, body}) => {
    return (
        
        <div id={item_id} className="flex flex-col min-w-full ">
        
        { image !== undefined && image !== "" && (
            <>
            <img src={image} className="mt-8 mx-4 rounded-xl" />
            </>
        )}

        <div className="flex-none px-4">
            <div className="flex flex-col">
                <div className="py-8">
                    { visual_id !== undefined && visual_id !== "" && (
                        <h3 className="pb-3 text-xl text-lynk-heading-small text-lynk-turquoise-500">{visual_id}</h3>
                    )}
                    <h3 className="text-xl text-lynk-heading-small text-white">{title}</h3>
                    <p className="pt-3 text-base text-lynk-body-medium-regular text-lynk-neutral-200 body-description">{parse(body)}</p>
                </div>
                <div className="w-full border-t bg-lynk-neutral-400" />
            </div>
        </div>
    </div>
    )
  };

  export default ListItem
  