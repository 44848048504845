import ListItem from "../list/list-item";
import { useEffect } from "react";
import { useState } from "react";

export default function List({content}) {

    const [items, setItems] = useState([]);
    useEffect(() => {
        var listItems = content.items
        setItems(listItems);
    });

    // UI

    if (items) {
        return (
            <>
            <div className="flex flex-col container mx-auto min-h-screen pb-8 bg-lynk-black max-w-xl relative antialiased">
                <div>
                    <h1 className="text-3xl text-lynk-heading-large leading-8 text-white px-4 py-10 pb-2">{content.title}</h1>
                </div>
                {items.map((item) => (
                    <ListItem key={item.id} item_id={item.id} visual_id={item.visual_id} image={item.image_path} title={item.title} body={item.body} />
                ))}
            </div>
            </>
        );
    } 
  }