import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';


export default function Root() {
    return (
        <>
            <Outlet />
        </>
    );
}

