import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
    <div className="min-h-full text-white py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div className="mx-auto max-w-max">
      <div className="sm:flex">
        <p className="text-4xl font-bold tracking-tight sm:text-5xl">404</p>
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
            <p className="mt-1 text-base text-gray-500">{error.statusText || error.message}</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}