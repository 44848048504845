import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import List from "../core-views/list";
import Carousel from "../core-views/carousel";

// Set up manifest path. This should be configured in the build pipeline
if (process.env.REACT_APP_MANIFEST_PATH === undefined) { 
    console.log("No manifest file added as environment variable. Please add it in the build pipeline.")
    var manifest = "" 
}
if (process.env.REACT_APP_MANIFEST_PATH) { 
    var manifest = process.env.REACT_APP_MANIFEST_PATH
 }

 // Check against this to makse sure the view type is valid
const validTypes = ["list", "carousel"]

export default function Core() {

    const routeParams = useParams()
    const [pages, setPages] = useState([]);
    const [content, setContent] = useState([]);
    useEffect(() => {
          async function getPages() {
            if (manifest === "") { return console.log("Manifest path is empty. Will not attempt to fetch any data.")}
            const response = await fetch(manifest);
            const data = await response.json();
            setPages(data);
            getSelectedPage(data);
          };

          async function getSelectedPage(pagesData) {
            var selectedPage = pagesData.findIndex((item) => item.path === routeParams.path);
            if (selectedPage === -1) { return }; // page does not exist
            var selectedPageData = pagesData[selectedPage];
            const response = await fetch(selectedPageData.data);
            const data = await response.json();
   
            var selectedLocale = data.findIndex((item) => item.locale === routeParams.locale);
            if (selectedLocale === -1) { return }; // locale does not exist
            console.log("Selected locale: " + routeParams.locale);
            var selectedLocaleData = data[selectedLocale];
            setContent(selectedLocaleData);
          }

          if (pages.length === 0) {
            getPages();
          } 
     }, []);


    // Handle URL parameters
    
    // const [locale, setLocale] = useState();
    // const [path, setPath] = useState();
    const [type, setType] = useState();
    // const [parametersConfigured, setParametersConfigured] = useState(false);
    useEffect(() => {
        // Locale 
        // if (validLocales.includes(routeParams.locale)) { 
        //     setLocale(routeParams.locale);
        //     // console.log("Locale: " + locale)
        // } else {
        //     return
        // };
        // Path
        //var validPaths = pages.map((item) => item.path);
        // if (validPaths.includes(routeParams.path)) { 
            // setPath(routeParams.path);
        //} else {
        //    return
        //};
        // Type
        if (validTypes.includes(routeParams.type)) { 
            setType(routeParams.type);
            // console.log("Type: " + type)
        } else {
            return
        };
        // Mark config as complete
        // setParametersConfigured(true);
    });


    // UI

    if (content.length !== 0 && type == "list") {
        return (
            <div>
                <List
                    content={content}
                />
            </div>
        )
    } else if (content.length !== 0 && type == "carousel") {
        return (
            <div>
                <Carousel
                    content={content}
                />
            </div>
        )
    }

  }