import { useParams } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import CarouselItem from "../carousel/carousel-item";

const buttonLocale = [
    {
        "locale": "en",
        "content": "Next"
    },
    {
        "locale": "sv-se",
        "content": "Nästa"
    },
    {
        "locale": "nl-nl",
        "content": "Volgend"
    },
    {
        "locale": "nl-be",
        "content": "Volgend"
    },
    {
        "locale": "fr-be",
        "content": "Suivant"
    },
    {
        "locale": "fr-fr",
        "content": "Suivant"
    },
    {
        "locale": "de-de",
        "content": "Nächste"
    },
    {
        "locale": "es-es",
        "content": "Siguiente"
    },
    {
        "locale": "it-it",
        "content": "Seguente"
    }
]

export default function Carousel({content}) {

    const items = content.items


    // USED BY BUTTONS
    const doNothing = () => {
        //does nothing
    }

    // //NAVIGATE WITH BUTTONS
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? items.length - 1 : currentIndex - 1;
        //console.log("Going to slide:", newIndex);
        var element = document.getElementById(newIndex);
        element.scrollIntoView({behavior:"smooth"});
        //setCurrentIndex(newIndex);
    };
    const goToNext = () => {
        const isLastSlide = currentIndex === items.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        //console.log("Going to slide:", newIndex);
        var element = document.getElementById(newIndex);
        element.scrollIntoView({behavior:"smooth"});
        //setCurrentIndex(newIndex);
    };





    // // CALCULATE SIZE OF CAROUSEL
    const [carouselSize, setCarouselSize] = useState(0);    
    const ref = useRef(null);
    useEffect(() => {
        const elementSize = ref.current ? ref.current.offsetWidth : 0;
        const carouselSize =  elementSize * items.length;
        //console.log('Slide width is:', ref.current ? ref.current.offsetWidth : 0);
        setCarouselSize(carouselSize);
    }, [ref.current]);

    // NEW STUFF
    function createCarouselSize(ref) {
        const elementSize = ref.current ? ref.current.offsetWidth : 0;
        const carouselSize =  elementSize * items.length;
        //console.log('Slide width is:', ref.current ? ref.current.offsetWidth : 0);
        setCarouselSize(carouselSize);
    }




    //CREATE AN INDEX OF BREAKPOINTS
    const incrementIndex = []
    function createIncrementsIndex(increments) {
        for (var i = 0; i <= increments; i++) {
            const increment = i * (carouselSize / items.length);
            incrementIndex.push(increment);
        };
        //console.log(incrementIndex);
    };



    
    // SCROLL HANDLING
    const [scrollPosition, setScrollPosition] = useState(0);
    function handleScroll() {
        const position = ref.current.scrollLeft;
        setScrollPosition(position);
    };
    useEffect(() => {
        const el = ref.current;
        el.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            el.removeEventListener('scroll', handleScroll);
        };
    }, []);




    // RESIZE HANDLING
    const [dimensions, setDimensions] = useState();
    function handleResize()  {
        const width = window.innerWidth;
        setDimensions(width);
        createIncrementsIndex(items.length - 1);
        createCarouselSize(ref);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    

     //CHECK IF THE CURRENT SCROLL POSITION HITS A BREAKPOINT
    useEffect(() => {

        createIncrementsIndex(items.length - 1);
            var window_width = window.innerWidth;
            var scrolledSlide = testScrollPosition(incrementIndex, scrollPosition, window_width);
            function testScrollPosition(index, pos, slide_width) {
                var hit = ""
                var central_slide_breakpoint = (slide_width / 2);
                index.map((breakpoint, i) => {
                    var breakpoint_middle = breakpoint - central_slide_breakpoint
                    var breakpoint_end = breakpoint + central_slide_breakpoint
                    if (pos >= breakpoint_middle && pos <= breakpoint_end) {
                        hit = i
                    };
                });
                return hit
            };
            setCurrentIndex(scrolledSlide);
    });



    // HANDLE BUTTON TEXT
    const routeParams = useParams()
    const [buttonText, setButtonText] = useState("");
    useEffect(() => {
        var buttonLocaleIdentifier = buttonLocale.findIndex((item) => item.locale === routeParams.locale);
        if (buttonLocaleIdentifier === -1) { setButtonText("Next"); }; // locale does not exist. fallback to English
        var selectedButtonText = buttonLocale[buttonLocaleIdentifier].content
        setButtonText(selectedButtonText);
    });


     console.log(currentIndex);



    // UI
    if (items) {

        return (
            <div ref={ref} className="flex overflow-hidden overflow-x-scroll container mx-auto min-h-screen bg-lynk-black min-w-full snap-mandatory snap-x scrollbar-hide relative antialiased">
                {items.map((item) => (
                    <CarouselItem key={item.id} item_id={item.id} image={item.image_path} title={item.title} body={item.body} />
                ))}
            <div>
          </div>
                <div className="fixed bottom-0 left-0 px-4 pb-0 min-w-full">
    
                    <div className="flex gap-2">
                            
                            <div 
                            className={`w-full transition-opacity transition-250 ${currentIndex === (items.length - 1) ? "display opacity-100" : "hidden opacity-0"}`}
                            >
    
                            </div>
    
                            <div 
                            className={`w-full transition-opacity transition-250 ${currentIndex === (items.length - 1) ? "hidden opacity-0 " : "display opacity-100"}`}
                            >
                                    <button
                                        type="button"
                                        className="btn-primary w-full rounded-full border-2 border-transparent bg-lynk-turquoise-500 px-4 py-3 text-base text-lynk-body-medium text-black shadow-none focus:outline-none focus:ring-none text-center"
                                        onClick={goToNext}
                                    >
                                        {buttonText}
                                    </button>
                            </div>
    
                            
    
                    </div>
    
                    <div className="flex w-full justify-center pt-4">
                        {items.map((slide, slideIndex) => (    
                            <div  
                            className={`opacity-25 text-white text-xs px-1 transition-opacity ${slideIndex === currentIndex ? "opacity-100" : ""}`}
                            style={slideIndex === currentIndex ? {opacity: '1'} : {}}
                            id={slideIndex} 
                            key={slideIndex}
                            onClick={doNothing}
                            >
                                ●    
                            </div>
                        ))}
                    </div>
    
                </div>
    
            </div>
        );

    };

  }