const parse = require('html-react-parser');

const CarouselItem = ({item_id, image, title, body}) => {
    return (
        <div id={item_id} className="flex flex-col min-h-screen min-w-full snap-center snap-always">
        {/* <div className="grow flex flex-col justify-between bg-center bg-cover" style={{ backgroundImage: "url(../borrow-car-onboarding-3.png)" }}> */} 
        <div className="grow flex flex-col justify-between bg-center bg-cover" style={{ backgroundImage: `url(${image})` }}>  
            <div className=""></div>
            <div className="">
               {/* <img src={ovalimg}></img> */} 
            </div>  
        </div>

        <div className="flex-none px-4">
            <div className="flex flex-col">
                <div className="py-8 px-3">
                    <h3 className="text-2xl text-lynk-heading-large text-center text-white">{title}</h3>
                    <p className="pt-3 text-body text-lynk-body-regular text-center text-gray-300 body-description">{parse(body)}</p>
                </div>
                <div className="h-20">
                </div>
            </div>
        </div>
    </div>
    )
  };

  export default CarouselItem
  